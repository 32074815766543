import Image2 from '../../assets/bnbclone.png'
import Image3 from '../../assets/chat.png'



const data = [
    {
        id: 1,
        category: 'fullstack',
        image: Image2,
        title: "AirBnb Clone",
        desc: "NextJS(Frontend) and Django REST API (Backend).",
        techStack: ["Next.js", "Django REST API", "PostgreSQL", "Docker"],
        demo: '#',
        github: 'https://github.com/Kamire-J/django-nextjs-app'
    },
    {
        id: 2,
        category: 'backend',
        image: Image3,
        title: "Chat Application",  
        desc: "Chat Application using Django Channels and React.",
        techStack: ["Django Channels", "React", "PostgreSQL"],
        demo: '#',  
        github: 'https://github.com/Kamire-J/django-react'
    }

    
]


export default data