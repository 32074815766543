import Card from '../../components/Card'


const Project = ({ project }) => {
  return (
    <Card className="portfolio__project">
      <div className="portfolio__project-image">
        <img src={project.image} alt="portfolio card" />
      </div>
      <h4>{project.title}</h4>
      <p style={{ textAlign: 'justify' }}>{project.desc}</p>

      {/* Tech Stack Highlight */}
      <div className="portfolio__project-tech">
        {project.techStack.map((tech, index) => (
          <span key={index} className="tech-badge">{tech}</span>
        ))}
      </div>

      <div className="portfolio__project-cta">
        <a href={project.demo} className="btn sm" target="_blank" rel="noopener noreferrer">Demo</a>
        <a href={project.github} className="btn sm" target="_blank" rel="noopener noreferrer">Github</a>
      </div>
    </Card>
  );
};

export default Project;
